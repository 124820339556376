<template>
  <DialogFormWrapper hide-actions>
    <template #form>
      <div v-if="dataFetched">
        <v-btn
          v-for="(order, index) in orders"
          :ref="`button${index}`"
          :key="order.id"
          class="client-button mb-4"
          color="primary"
          max-width="100%"
          dark
          block
          @click="findCourse(order)"
        >
          {{ getOrderLabel(order) }}
        </v-btn>
      </div>
      <div
        v-else
        class="d-flex justify-center"
      >
        <v-progress-circular
          class="mx-auto"
          indeterminate
          color="primary"
        />
      </div>
    </template>
  </DialogFormWrapper>
</template>

<script>
import DialogFormWrapper from './Partials/DialogFormWrapper'
import { mapActions, mapState } from 'vuex'
export default {
  components: {
    DialogFormWrapper,
  },
  data: () => ({
    dataFetched: false
  }),
  computed: {
    ...mapState({
      orders: state => state.orders.items,
      department: state => state.core.department,
      container: state => state.layout.dialog.item,
    }),
  },
  mounted () {
    this.getCourses({
      params: {
        departmentId: this.department.id,
        filters: {
          containerId: this.container.id,
        }
      }
    })
      .then(() => { this.dataFetched = true })
  },
  methods: {
    ...mapActions({
      getCourses: 'orders/getItems',
      setTableDate: 'courses/setTableDate',
    }),
    getOrderLabel (order) {
      const { refNumber, address, courses } = order || {}
      const course = courses[1] || courses[0]
      return `${refNumber}, ${course.dueDate}, ${address?.formattedAddress}, `
    },
    findCourse (order) {
      const course = order.courses[1] || order.courses[0]
      this.setTableDate({ date: [course.dueDate] })
      this.$router.push({ name: 'courses', query: { activeItemId: course.id } })
    }
  }
}
</script>
